import { useStudyPlan } from "../../../../../app/contexts/study.context";
import deleteIcon from "../../../../../app/images/deleteIcon.png";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import styled from "styled-components";
import { TextField, Tooltip } from "@material-ui/core";

const useTooltipStyles = makeStyles({
  tooltip: {
    fontSize: "1rem"
  }
});

const useInputStyles = makeStyles(() => ({
  root: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none",
    },
    "& input[type=number]": {
      textAlign: "center",
      "-moz-appearance": "textfield",
    },
  },
}));

const TOTAL_COLUMNS = 11;

const isLastFilledCell = (profiles, currentIndex) => {
  const lastFilledIndex = profiles
    .map((profile, index) => ({ hasValue: profile.siteActivationProfilePct !== null, index }))
    .filter(item => item.hasValue)
    .pop()?.index;
  
  return currentIndex === lastFilledIndex;
};

const ActivationProfilesTable = ({
  activationProfiles,
  FSIV,
  siteGroupId,
  countryId,
}) => {
  const inputClasses = useInputStyles();
  const tooltipClasses = useTooltipStyles();
  const [disabledCellIndex, setDisabledCellIndex] = React.useState(null);
  const debounceTimerRef = React.useRef(null);

  React.useEffect(() => {
    // Find the last filled cell index
    const lastFilledIndex = activationProfiles
      .map((profile, index) => ({ hasValue: profile.siteActivationProfilePct !== null, index }))
      .filter(item => item.hasValue)
      .pop()?.index;

    // Clear any existing timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new timer to update the disabled cell index
    debounceTimerRef.current = setTimeout(() => {
      setDisabledCellIndex(lastFilledIndex);
    }, 1000); // 1 second delay

    // Cleanup timer on unmount or when dependencies change
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, [activationProfiles]);

  const { updateCountries } = useStudyPlan();

  const handleChange = (value, index, property) => {
    const newActivationProfiles = [...activationProfiles];
    if (property === "siteActivationProfilePct") {
      newActivationProfiles[index][property] =
        value === "" ? null : parseFloat(value);
    } else if (property === "siteActivationProfileDays") {
      newActivationProfiles[index][property] =
        value === "" ? null : parseInt(value, 10);
    } else {
      newActivationProfiles[index][property] = value;
    }

    if (
      value !== "" &&
      newActivationProfiles[index].siteActivationLabel === ""
    ) {
      newActivationProfiles[index].siteActivationLabel = "Projected using PP";
    }

    if (
      newActivationProfiles[index].siteActivationProfilePct === null &&
      newActivationProfiles[index].siteActivationProfileDays === null
    ) {
      newActivationProfiles[index].siteActivationLabel = "";
    }

    const profileId = newActivationProfiles[index].siteActivationProfileId;
    if (
      value !== "" &&
      typeof profileId === "string" &&
      profileId.startsWith("empty-")
    ) {
      newActivationProfiles[index].siteActivationProfileId = profileId.replace(
        "empty-",
        "",
      );
    }

    const update = {
      activationProfiles: newActivationProfiles.filter(
        (profile) =>
          profile.siteActivationProfilePct !== null ||
          profile.siteActivationProfileDays !== null ||
          (typeof profile.siteActivationProfileId === "string" &&
            !profile.siteActivationProfileId.startsWith("empty-")),
      ),
    };
    updateCountries(countryId, siteGroupId, update);
  };

  const handleDelete = (profileId) => {
    const newActivationProfiles = [...activationProfiles];

    const update = {
      activationProfiles: newActivationProfiles.filter(
        (profile) => profile.siteActivationProfileId !== profileId,
      ),
    };
    updateCountries(countryId, siteGroupId, update);
  };

  return (
    <Table>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Label</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={profile.siteActivationProfileId}>
            <Typography>{profile.siteActivationLabel || ""}</Typography>
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong># of Sites</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={`percentage-${index}`}>
            {index === disabledCellIndex ? (
              <Tooltip 
                title="This cell can be updated by updating Number Of Activated Sites."
                placement="top"
                classes={{
                  tooltip: tooltipClasses.tooltip
                }}
              >
                <span style={{ width: "80%" }}>
                  <TextField
                    style={{ 
                      width: "100%",
                      backgroundColor: 'var(--Grey-1)'
                    }}
                    type="number"
                    inputProps={{min: "0"}}
                    classes={inputClasses}
                    value={profile.siteActivationProfilePct ?? ""}
                    onChange={(event) =>
                      handleChange(
                        event.target.value,
                        index,
                        "siteActivationProfilePct",
                      )
                    }
                    disabled={true}
                    variant="outlined"
                    size="small"
                  />
                </span>
              </Tooltip>
            ) : (
              <TextField
                style={{ 
                  width: "80%",
                  backgroundColor: 'transparent'
                }}
                type="number"
                inputProps={{min: "0"}}
                classes={inputClasses}
                value={profile.siteActivationProfilePct ?? ""}
                onChange={(event) =>
                  handleChange(
                    event.target.value,
                    index,
                    "siteActivationProfilePct",
                  )
                }
                variant="outlined"
                size="small"
              />
            )}
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Days</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile, index) => (
          <Cell key={`percentage-${index}`}>
            <TextField
              style={{ width: "80%" }}
              type="number"
              classes={inputClasses}
              value={profile.siteActivationProfileDays ?? ""}
              onChange={(event) =>
                handleChange(
                  event.target.value,
                  index,
                  "siteActivationProfileDays",
                )
              }
              variant="outlined"
              size="small"
            />
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Date</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile) => (
          <Cell key={profile.siteActivationProfileId}>
            <Typography>
              {FSIV.milestoneDateMillis != null && profile.siteActivationProfileDays != null
                ? moment(
                    FSIV.milestoneDateMillis +
                    profile.siteActivationProfileDays * 24 * 60 * 60 * 1000
                  )
                  .utc()
                  .format("DD/MMM/YYYY")
                : ""}
            </Typography>
          </Cell>
        ))}
      </Row>
      <Row repeat={TOTAL_COLUMNS}>
        <Cell variant="header">
          <Typography align="right">
            <strong>Delete</strong>
          </Typography>
        </Cell>
        {activationProfiles.map((profile) => (
          <Cell key={profile.siteActivationProfileId}>
            {profile.siteActivationProfileDays !== null ? (
              <DeleteButton
                onClick={() => handleDelete(profile.siteActivationProfileId)}
              >
                <img
                  src={deleteIcon}
                  alt="Delete icon"
                />
              </DeleteButton>
            ) : (
              ""
            )}
          </Cell>
        ))}
      </Row>
    </Table>
  );
};

export default ActivationProfilesTable;

const Table = styled.div`
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  border: 1px solid var(--Grey-4);
  border-radius: 4px;
  border-right: none;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 120px repeat(${TOTAL_COLUMNS}, 1fr);
  height: 50px;
  border-bottom: 1px solid var(--Grey-4);
  &:last-child {
    border: none;
  }
`;

const Cell = styled.div`
  background: ${(p) =>
    p.variant === "header" ? "var(--Grey-1)" : "transparent"};
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid var(--Grey-4);

  &:first-child {
    justify-content: flex-end;
    padding-right: 1em;
  }
`;

const DeleteButton = styled.div`
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.2s;
  }
`;