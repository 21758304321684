import {
  COUNTRY_HEADERS as ASSESSMENT_COUNTRY_HEADERS,
  HEADERS as ASSESSMENT_HEADERS,
  ASSESSMENT_TYPES_WITH_SITE_COST,
} from "../assessments.table/table.utils";
import {
  BodyStyle,
  DEFAULT_DATE_CONFIG,
  HeaderStyle1,
  getCellType,
} from "../../../../app/contexts/helpers";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import {
  INITIAL_RISK_ASSESSMENT_COLORS,
  VSS_HEADER_COLORS,
} from "../constants";
import {
  MAPPING_TOTAL_HEADERS,
  HEADERS as SOE_HEADERS,
} from "../soe.table/table.utils";
import React, { useMemo, useState } from "react";
import {
  COUNTRY_HEADERS as VISIT_COUNTRY_HEADERS,
  HEADERS as VISIT_HEADERS,
} from "../visits.table/table.utils";

import { INITIAL_RISK_ASSESSMENT_HEADERS } from "../initial.risk.assessment.table/table.utils";
import { Modal } from "../../../../app/components";
import XLSX from "xlsx-js-style";
import styled from "styled-components";
import { useScheduleEvents } from "../../../../app/contexts/schedule.events.context";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

export default function ExcelExportsModal({ open, handleClose }) {
  const [isChecked, setIsChecked] = useState({});
  const [exportState, setExportState] = useState("idle");
  const {
    scheduleOfEventsVisits,
    scheduleOfEventsAssessments,
    visitsAndAssessmentsMapping,
    currentSOE,
    SOECountries,
    fetchSOECountryVisits,
    fetchSOECountryAssessments,
    totalMappings,
    initialRiskAssessments,
    initialRiskAssessmentTotal,
    visitSampleStrategy,
    variablesData,
    ePrice,
    CRA,
    IMV,
    CDA,
    CDAStudy,
    ePriceInfo,
  } = useScheduleEvents();

  // Memoized values for country and site level schedule events
  const countryLevel = useMemo(() => {
    return SOECountries.filter(
      (country) => country.siteId === 1 && country.siteGroupId !== 0,
    );
  }, [SOECountries]);

  const siteLevel = useMemo(() => {
    return SOECountries.filter(
      (country) => country.siteId !== 1 && country.siteGroupId !== 0,
    );
  }, [SOECountries]);

  // Memoized values to check if there are visits, assessments, etc.
  const hasVisits = useMemo(
    () => scheduleOfEventsVisits["study"]?.length > 0,
    [scheduleOfEventsVisits],
  );
  const hasAssessments = useMemo(
    () => scheduleOfEventsAssessments["study"]?.length > 0,
    [scheduleOfEventsAssessments],
  );

  const hasAssessmentForSOE = useMemo(
    () =>
      scheduleOfEventsAssessments["study"].filter((sa) =>
        ["Procedure", "Non Procedure"].includes(sa.assessmentType),
      ).length,
    [scheduleOfEventsAssessments],
  );

  const hasInitialRiskAssessment = useMemo(
    () => initialRiskAssessments,
    [initialRiskAssessments],
  );

  const hasVisitSamplingStrategy = useMemo(
    () => visitSampleStrategy?.length > 0,
    [visitSampleStrategy],
  );

  // Filename for the exported file based on current schedule of events
  const filename_base = useMemo(
    () =>
      `${(currentSOE.scheduleOfEventsName || "").replaceAll(
        " ",
        "_",
      )}_${new Date().toString()}_${currentSOE.studyId}`,
    [currentSOE],
  );

  const handleChange = (e, value) => {
    const { name } = e.target;
    setIsChecked((prev) => ({ ...prev, [name]: value }));
  };

  const resetModal = () => {
    setIsChecked({});
    handleClose();
  };

  // Function to style visit sampling strategy table content
  const styleVisitSamplingStrategyTableContent = (tableContent) => {
    const styledTableContent = [];
    let colorIndex = 0;
    let isHeader = true;
    for (let i = 0; i < tableContent.length; i++) {
      const color =
        VSS_HEADER_COLORS[Math.min(colorIndex, VSS_HEADER_COLORS.length - 1)];
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value ?? "",
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s: isHeader
            ? { ...HeaderStyle1, fill: { fgColor: { rgb: color } } }
            : j === 0
            ? {
                ...BodyStyle,
                alignment: { wrapText: true, horizontal: "right" },
              }
            : BodyStyle,
        });
      }

      isHeader = tableContent[i].every((item) => item === "");
      if (isHeader) colorIndex++;
    }
    return styledTableContent;
  };

  // Function to style table content
  const styleTableContent = (tableContent) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value ?? "",
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s: i === 0 ? HeaderStyle1 : BodyStyle,
        });
      }
    }
    return styledTableContent;
  };

  const returnHeaderColor = (value) => {
    if (value === "Low")
      return INITIAL_RISK_ASSESSMENT_COLORS.GREEN.replace("#", "");
    if (value === "Moderate")
      return INITIAL_RISK_ASSESSMENT_COLORS.YELLOW.replace("#", "");
    if (value === "High")
      return INITIAL_RISK_ASSESSMENT_COLORS.RED.replace("#", "");
    return "D3D3D3";
  };

  const returnTotalColor = (total) => {
    if (total > 26) {
      return INITIAL_RISK_ASSESSMENT_COLORS.RED.replace("#", "");
    } else if (total > 18 && total <= 26) {
      return INITIAL_RISK_ASSESSMENT_COLORS.YELLOW.replace("#", "");
    } else {
      return INITIAL_RISK_ASSESSMENT_COLORS.GREEN.replace("#", "");
    }
  };

  // Function to style risk assessment table content
  const styleRiskAssessmentTableContent = (tableContent) => {
    const styledTableContent = [];
    for (let i = 0; i < tableContent.length; i++) {
      if (!styledTableContent[i]) styledTableContent.push([]);
      for (let j = 0; j < tableContent[i].length; j++) {
        const value = tableContent[i][j];
        const type = getCellType(value);
        styledTableContent[i].push({
          v: value ?? "",
          ...(type === "d" ? { z: "dd mmm yyyy" } : {}),
          t: type,
          s:
            i === 0 // Importing various constants and utilities from other files
              ? {
                  ...HeaderStyle1,
                  fill: { fgColor: { rgb: returnHeaderColor(value) } },
                }
              : {
                  ...BodyStyle,
                  alignment: { wrapText: true, horizontal: "left" },
                  ...(i === 12 && j === 2
                    ? {
                        fill: { fgColor: { rgb: returnTotalColor(value) } },
                        alignment: { wrapText: true, horizontal: "center" },
                      }
                    : {}),
                },
        });
      }
    }
    return styledTableContent;
  };

  const download_soe = () => {
    const soeContent = [
      ["Activities", ...SOE_HEADERS(scheduleOfEventsVisits["study"])],
    ];
    scheduleOfEventsAssessments["study"]
      .filter((sa) =>
        ["Procedure", "Non Procedure"].includes(sa.assessmentType),
      )
      .forEach((assessment) => {
        soeContent.push([
          `${assessment.assessmentType} - ${assessment.assessmentName}`,
          ...visitsAndAssessmentsMapping[
            assessment.scheduleOfEventsAssessmentId || assessment.key
          ]?.map((v) => (v.value ? "X" : "")),
        ]);
      });

    const totals = MAPPING_TOTAL_HEADERS.map((header, idx) => {
      return [header, ...totalMappings[idx]];
    });
    return styleTableContent([...soeContent, ...totals]);
  };

  const download_country_assessments = async () => {
    const countryValues = {};
    let results = await Promise.all(
      countryLevel?.map((soeC) => {
        if (soeC.siteGroupId === 0) return null;
        if (
          !scheduleOfEventsAssessments[`${soeC.siteGroupId}-${soeC.siteId}`]
        ) {
          return fetchSOECountryAssessments(
            soeC.scheduleOfEventsId,
            soeC.siteGroupId,
            soeC.siteId,
          );
        }
        return null;
      }),
    );

    results.forEach((res, idx) => {
      if (res) {
        countryValues[
          `${countryLevel[idx].siteGroupId}-${countryLevel[idx].siteId}`
        ] = res;
      }
    });

    const countryAssessmentsContent = scheduleOfEventsAssessments["study"]?.map(
      (visit, idx) => {
        return [
          visit.assessmentType,
          visit.assessmentName,
          visit.assessmentNameMapping,
          ...countryLevel
            .filter((soc) => soc.siteGroupId !== 0)
            ?.map(
              (soc) =>
                (scheduleOfEventsAssessments[
                  `${soc.siteGroupId}-${soc.siteId}`
                ] || countryValues[`${soc.siteGroupId}-${soc.siteId}`])[idx]
                  ?.assessmentCost ?? "",
            ),
        ];
      },
    );

    countryAssessmentsContent.unshift([
      ...ASSESSMENT_COUNTRY_HEADERS(countryLevel),
    ]);

    return styleTableContent(countryAssessmentsContent);
  };

  const download_site_assessments = async () => {
    const siteValues = {};
    let results = await Promise.all(
      siteLevel?.map((soeC) => {
        if (soeC.siteGroupId === 0) return null;
        if (
          !scheduleOfEventsAssessments[`${soeC.siteGroupId}-${soeC.siteId}`]
        ) {
          return fetchSOECountryAssessments(
            soeC.scheduleOfEventsId,
            soeC.siteGroupId,
            soeC.siteId,
          );
        }
        return null;
      }),
    );

    results.forEach((res, idx) => {
      if (res) {
        siteValues[`${siteLevel[idx].siteGroupId}-${siteLevel[idx].siteId}`] =
          res;
      }
    });
    const siteAssessmentsContent = scheduleOfEventsAssessments["study"]?.map(
      (visit, idx) => {
        return [
          visit.assessmentType,
          visit.assessmentName,
          visit.assessmentNameMapping,
          ...siteLevel
            .filter((soc) => soc.siteGroupId !== 0)
            ?.map(
              (soc) =>
                (scheduleOfEventsAssessments[
                  `${soc.siteGroupId}-${soc.siteId}`
                ] || siteValues[`${soc.siteGroupId}-${soc.siteId}`])[idx]
                  ?.assessmentCost ?? "",
            ),
        ];
      },
    );

    siteAssessmentsContent.unshift([...ASSESSMENT_COUNTRY_HEADERS(siteLevel)]);
    return styleTableContent(siteAssessmentsContent);
  };

  const download_assessments = () => {
    const assessmentContent = scheduleOfEventsAssessments["study"]?.map(
      (item) => {
        const isSiteCostType = ASSESSMENT_TYPES_WITH_SITE_COST.includes(
          item.assessmentType,
        );
        return [
          item.assessmentType,
          item.assessmentName,
          item.assessmentNameMapping,
          item.assessmentCost,
          isSiteCostType ? "-" : item.overhead ? "X" : "",
          isSiteCostType ? "-" : item.exclude ? "X" : "",
          isSiteCostType ? "-" : item.ipAdministered ? "X" : "",
          item.commentsAssessment || "",
          item.commentsCost || "",
          item.commentsClinicalStrategy || "",
          item.commentsDataManagement || "",
        ];
      },
    );
    assessmentContent.unshift([...Object.values(ASSESSMENT_HEADERS)]);

    return styleTableContent(assessmentContent);
  };

  const download_country_visits = async () => {
    const countryValues = {};
    let results = await Promise.all(
      countryLevel?.map((soeC) => {
        if (soeC.siteGroupId === 0) return null;
        if (!scheduleOfEventsVisits[`${soeC.siteGroupId}-${soeC.siteId}`]) {
          return fetchSOECountryVisits(
            soeC.scheduleOfEventsId,
            soeC.siteGroupId,
            soeC.siteId,
          );
        }
        return null;
      }),
    );

    results.forEach((res, idx) => {
      if (res) {
        countryValues[
          `${countryLevel[idx].siteGroupId}-${countryLevel[idx].siteId}`
        ] = res;
      }
    });
    const countryVisitsContent = scheduleOfEventsVisits["study"]?.map(
      (visit, idx) => {
        return [
          visit.visitCategory,
          visit.visitName,
          visit.visitStudyDay,
          visit.visitVarianceType,
          visit.visitVariance,
          ...countryLevel
            .filter((soc) => soc.siteGroupId !== 0)
            ?.map(
              (soc) =>
                (scheduleOfEventsVisits[`${soc.siteGroupId}-${soc.siteId}`] ||
                  countryValues[`${soc.siteGroupId}-${soc.siteId}`])[idx]
                  ?.visitCost ?? "",
            ),
        ];
      },
    );

    countryVisitsContent.unshift([
      ...VISIT_COUNTRY_HEADERS(currentSOE, countryLevel),
    ]);
    return styleTableContent(countryVisitsContent);
  };

  const download_site_visits = async () => {
    const countryValues = {};
    let results = await Promise.all(
      siteLevel?.map((soeC) => {
        if (soeC.siteGroupId === 0) return null;
        if (!scheduleOfEventsVisits[`${soeC.siteGroupId}-${soeC.siteId}`]) {
          return fetchSOECountryVisits(
            soeC.scheduleOfEventsId,
            soeC.siteGroupId,
            soeC.siteId,
          );
        }
        return null;
      }),
    );
  
    results.forEach((res, idx) => {
      if (res) {
        countryValues[
          `${siteLevel[idx].siteGroupId}-${siteLevel[idx].siteId}`
        ] = res;
      }
    });
    
    const siteVisitsContent = scheduleOfEventsVisits["study"]?.map(
      (visit, idx) => {
        return [
          visit.visitCategory,
          visit.visitName,
          visit.visitStudyDay,
          visit.visitVarianceType,
          visit.visitVariance,
          ...siteLevel
            .filter((soc) => soc.siteGroupId !== 0)
            ?.map(
              (soc) => {
                const siteVisit = (scheduleOfEventsVisits[`${soc.siteGroupId}-${soc.siteId}`] ||
                  countryValues[`${soc.siteGroupId}-${soc.siteId}`])[idx];
                return siteVisit?.visitCostTotal ?? "";
              }
            ),
        ];
      },
    );
  
    siteVisitsContent.unshift([
      ...VISIT_COUNTRY_HEADERS(currentSOE, siteLevel),
    ]);
    return styleTableContent(siteVisitsContent);
  };
  const download_visits = () => {
    const visitsContent = scheduleOfEventsVisits["study"]?.map((item) => {
      return [
        item.visitCategory || "",
        item.visitName || "",
        item.visitStudyDay || "",
        item.visitVarianceType || "",
        item.visitVariance || "",
        item.visitCost ?? "",
        item.miEstSDRSDV || "",
        item.miSampleVisit ? "X" : "",
        item.miSuggestedOnSiteSDRSDV || "",
        item.miSuggestedCDAReview || "",
        item.dmUniqueCRFs || "",
        item.dmNonUniqueCRFs || "",
        // item.dmIntegratedEpro || "",
        // item.dmPaperEpro || "",
        // item.dmCentralLab || "",
        // item.dmLocalLab || "",
        item.commentsVisit || "",
        item.commentsCost || "",
        item.commentsClinicalStrategy || "",
        item.commentsDataManagement || "",
      ];
    });

    visitsContent.unshift([...Object.values(VISIT_HEADERS(currentSOE))]);

    return styleTableContent(visitsContent);
  };

  const download_risk_assessment = () => {
    const riskAssessmentContent = initialRiskAssessments?.map((item) => {
      return [
        item.riskAssessmentCategory || "",
        item.riskAssessmentStudySpecifics || "",
        item.riskAssessmentRiskScore || "",
        item.riskAssessmentLow || "",
        item.riskAssessmentModerate || "",
        item.riskAssessmentHigh || "",
      ];
    });
    riskAssessmentContent.unshift([
      ...Object.values(INITIAL_RISK_ASSESSMENT_HEADERS),
    ]);
    riskAssessmentContent.push([
      "",
      initialRiskAssessmentTotal.label,
      initialRiskAssessmentTotal.total,
      "",
      "",
      "",
    ]);
    return styleRiskAssessmentTableContent(riskAssessmentContent);
  };

  const download_visit_sampling_strategy = () => {
    const visitSamplingStrategyContent = [];

    // header placeholders
    visitSamplingStrategyContent.push([null, null, null, null, null, null]);
    variablesData.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        "",
        "",
        "",
        item.notes,
      ]);
    });

    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push([null, null, null, null, null, null]);
    ePrice.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        "",
        "",
        "",
        item.notes,
      ]);
    });

    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push([
      "",
      "100% (traditional)",
      "Visit Sampling Modeling",
      "Difference from Traditional",
      "",
      "Notes",
    ]);
    CRA.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        item.cellValue2,
        item.cellValue3,
        "",
        item.notes,
      ]);
    });

    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push([null, null, null, null, null, null]);
    IMV.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        item.cellValue2,
        item.cellValue3,
        "",
        item.notes,
      ]);
    });
    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push(["", "Number", "Hours", "", "", "Notes"]);
    CDA.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        item.cellValue2,
        item.cellValue3,
        "",
        item.notes,
      ]);
    });
    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push(["", "Number", "Hours", "", "", "Notes"]);
    CDAStudy.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        item.cellValue2,
        item.cellValue3,
        "",
        item.notes,
      ]);
    });
    visitSamplingStrategyContent.push(["", "", "", "", "", ""]);
    visitSamplingStrategyContent.push([
      "",
      "Visit Sampling SDR/SDV Time (reduced SDR/SDV)",
      "100% SDR/SDV",
      "Data Review (CDA): KRI Review and Study Level Reporting Only",
      "SDR/SDV % Subject Visits",
      "Notes",
    ]);
    ePriceInfo.forEach((item) => {
      visitSamplingStrategyContent.push([
        item.rowLabel,
        item.cellValue1,
        item.cellValue2,
        item.cellValue3,
        item.cellValue4,
        item.notes,
      ]);
    });

    return styleVisitSamplingStrategyTableContent(visitSamplingStrategyContent);
  };

  const exportAsCSV = async () => {
    setExportState("exporting");
    try {
      const wb = XLSX.utils.book_new();
      if (isChecked.visits) {
        const visit_content = download_visits();
        const visits_sheet = XLSX.utils.aoa_to_sheet(
          visit_content,
          DEFAULT_DATE_CONFIG,
        );
        if (visit_content.length > 0) {
          const sheetCols = visit_content[0].map((value, index) =>
            index === 0 ? { width: 15 } : { width: 20 },
          );
          visits_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(wb, visits_sheet, "Visits Table");
      }

      if (isChecked.countryVisits) {
        const country_visits_content = await download_country_visits();
        const country_visits_sheet = XLSX.utils.aoa_to_sheet(
          country_visits_content,
          {},
        );

        if (country_visits_content.length > 0) {
          const sheetCols = country_visits_content[0].map((value, index) =>
            index < 5 ? { width: 15 } : { width: 50 },
          );
          country_visits_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(
          wb,
          country_visits_sheet,
          "Country Visits Table",
        );
      }

      if (isChecked.siteVisits) {
        const site_visits_content = await download_site_visits();
        const site_visits_sheet = XLSX.utils.aoa_to_sheet(
          site_visits_content,
          {},
        );

        if (site_visits_content.length > 0) {
          const sheetCols = site_visits_content[0].map((value, index) =>
            index < 5 ? { width: 15 } : { width: 50 },
          );
          site_visits_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(
          wb,
          site_visits_sheet,
          "Site Visits Table",
        );
      }
      if (isChecked.assessments) {
        const assessment_content = download_assessments();
        const assessments_sheet = XLSX.utils.aoa_to_sheet(
          assessment_content,
          {},
        );

        if (assessment_content.length > 0) {
          const sheetCols = assessment_content[0].map((value, index) =>
            index === 0 ? { width: 15 } : { width: 20 },
          );
          assessments_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(
          wb,
          assessments_sheet,
          "Assessments Table",
        );
      }

      if (isChecked.countryAssessments) {
        const country_assessments_content =
          await download_country_assessments();
        const country_assessments_sheet = XLSX.utils.aoa_to_sheet(
          country_assessments_content,
          {},
        );

        if (country_assessments_content.length > 0) {
          const sheetCols = country_assessments_content[0].map((value, index) =>
            index < 3 ? { width: 15 } : { width: 50 },
          );
          country_assessments_sheet["!cols"] = sheetCols;
        }

        XLSX.utils.book_append_sheet(
          wb,
          country_assessments_sheet,
          "Country Assessments Table",
        );
      }

      if (isChecked.siteAssessments) {
        const site_assessments_content = await download_site_assessments();
        const site_assessments_sheet = XLSX.utils.aoa_to_sheet(
          site_assessments_content,
          {},
        );

        if (site_assessments_content.length > 0) {
          const sheetCols = site_assessments_content[0].map((value, index) =>
            index < 3 ? { width: 15 } : { width: 50 },
          );
          site_assessments_sheet["!cols"] = sheetCols;
        }

        XLSX.utils.book_append_sheet(
          wb,
          site_assessments_sheet,
          "Site Assessments Table",
        );
      }

      if (isChecked.soe) {
        const soe_content = download_soe();
        const soe_sheet = XLSX.utils.aoa_to_sheet(soe_content, {});

        if (soe_content.length > 0) {
          const sheetCols = soe_content[0].map((value, index) =>
            index === 0 ? { width: 40 } : { width: 20 },
          );
          soe_sheet["!cols"] = sheetCols;
        }

        XLSX.utils.book_append_sheet(wb, soe_sheet, "SOE Table");
      }

      if (isChecked.initialRiskAssessment) {
        const risk_assessment_content = download_risk_assessment();
        const risk_assessment_sheet = XLSX.utils.aoa_to_sheet(
          risk_assessment_content,
          DEFAULT_DATE_CONFIG,
        );
        if (risk_assessment_content.length > 0) {
          const sheetCols = risk_assessment_content[0].map((value, index) =>
            [0, 1, 2].includes(index) ? { width: 15 } : { width: 40 },
          );
          risk_assessment_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(
          wb,
          risk_assessment_sheet,
          "Initial Risk Assessment",
        );
      }

      if (isChecked.visitSamplingStrategy) {
        const visit_sampling_strategy_content =
          download_visit_sampling_strategy();
        const visit_sampling_strategy_sheet = XLSX.utils.aoa_to_sheet(
          visit_sampling_strategy_content,
          {},
        );
        if (visit_sampling_strategy_content.length > 0) {
          const sheetCols = [1, 2, 3, 4, 5, 6].map((idx) =>
            idx === 1
              ? { width: 60 }
              : idx === 6
              ? { width: 80 }
              : { width: 20 },
          );
          visit_sampling_strategy_sheet["!cols"] = sheetCols;
        }
        XLSX.utils.book_append_sheet(
          wb,
          visit_sampling_strategy_sheet,
          "Visit Sampling Strategy",
        );
      }

      XLSX.writeFile(wb, `${filename_base}.xlsx`, XLSX.cellDates);

      setIsChecked({});
      setExportState("exported");
      handleClose();
    } catch (err) {
      console.log(err);
      setExportState("idle");
    }
  };

  const validExportValues = () => {
    const values = Object.values(isChecked);
    if (values.length === 0) return false;
    if (values.find((v) => v === true)) return true;
    return false;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        if (exportState !== "exporting") resetModal();
      }}
      aria-labelledby="Export Tables"
      aria-describedby="Export Tables"
      title="Export Tables"
      sx={{ minWidth: "30rem", maxWidth: "100%", width: "70rem" }}
    >
      <FormGroup>
        <Container>
          <SubContainer>
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasVisits}
                  checked={!!isChecked.visits}
                  onChange={handleChange}
                  name="visits"
                />
              }
              label={`Visits Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasAssessments}
                  checked={!!isChecked.assessments}
                  onChange={handleChange}
                  name="assessments"
                />
              }
              label={`Assessments Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasAssessmentForSOE || !hasVisits}
                  checked={!!isChecked.soe}
                  onChange={handleChange}
                  name="soe"
                />
              }
              label={`Export SOE`}
            />
          </SubContainer>
          <SubContainer>
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasVisits}
                  checked={!!isChecked.countryVisits}
                  onChange={handleChange}
                  name="countryVisits"
                />
              }
              label={`Country Visits Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasAssessments}
                  checked={!!isChecked.countryAssessments}
                  onChange={handleChange}
                  name="countryAssessments"
                />
              }
              label={`Country Assessments Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasInitialRiskAssessment}
                  checked={!!isChecked.initialRiskAssessment}
                  onChange={handleChange}
                  name="initialRiskAssessment"
                />
              }
              label={`Initial Risk Assessment`}
            />
          </SubContainer>
          <SubContainer>
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasVisits}
                  checked={!!isChecked.siteVisits}
                  onChange={handleChange}
                  name="siteVisits"
                />
              }
              label={`Site Visits Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasAssessments}
                  checked={!!isChecked.siteAssessments}
                  onChange={handleChange}
                  name="siteAssessments"
                />
              }
              label={`Site Assessments Table`}
            />
            <FormControlLabel
              style={{ width: "30%" }}
              control={
                <Checkbox
                  disabled={!hasVisitSamplingStrategy}
                  checked={!!isChecked.visitSamplingStrategy}
                  onChange={handleChange}
                  name="visitSamplingStrategy"
                />
              }
              label={`Visit Sampling Strategy`}
            />
          </SubContainer>
        </Container>
        <ButtonContainer>
          <SecondaryButton onClick={resetModal}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={!validExportValues() || exportState === "exporting"}
            onClick={exportAsCSV}
          >
            {exportState === "exporting" ? (
              <CircularProgress size={"1em"} />
            ) : (
              "Export"
            )}
          </PrimaryButton>
        </ButtonContainer>
      </FormGroup>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SubContainer = styled.div`
  display: flex;
  gap: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
