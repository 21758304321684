import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Description as DescriptionIcon,
  GetApp as GetAppIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import UnsavedChangesModal from "../../../common/unsaved.changes.modal";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import { useExportPlan } from "../../../study.plan/countries.and.site.groups/country.tabs/controls/export.plan/export.js";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";
import TertiaryButton from "../../../../app/components/buttons/tertiary";
import UploadPlanModal from "../../../study.plan/countries.and.site.groups/country.tabs/controls/add.bulk.countries.modal"
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: ".2em",
  },
}));

const Controls = ({ errors, hasErrors = [], fieldErrors = {} }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const [open, setOpen] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  const exportPlan = useExportPlan();
  const {
    studyPlan,
    savePlan,
    unsavedChange,
    saveLoading,
    validateSequentialCohort,
    validateSeasonalVariations,
    isSequential,
    hideEnrollmentDuration,
    clearStudyPlan,
  } = useStudyPlan();
  
  const {
    studyPlanName,
    croProjectStartDateMillis,
    studyPlanDescr,
    finalProtocolStartDateMillis,
    treatmentDuration,
    screeningPeriodDuration,
    overallEnrollmentDuration,
  } = studyPlan;
  const [sites_to_activate, subjects_to_screen] = studyPlan?.parameters;

  let milestones = [];
  if (
    studyPlan.countries[0] &&
    studyPlan.countries[0].siteGroups &&
    studyPlan.countries[0].siteGroups[0]
  ) {
    milestones = studyPlan.countries[0].siteGroups[0].milestones;
  }

  const isOverrideAndEmptyComment = milestones.some(
    (milestone) => milestone.isUserOverride && milestone.comments === "",
  );

  const handleBackToList = () => {
    if (unsavedChange) {
      setOpen(true);
    } else {
      history.push(`/ctpo/study-plans/${id}`);
    }
  };

  const handleCancel = () => {
    if (unsavedChange) {
      setOpen(true);
    } else {
      clearStudyPlan();
      history.push(`/ctpo/study-plans/${id}`);
    }
  };

  const handleSave = () => {
    const seqValid = isSequential
      ? validateSequentialCohort(history.location.pathname)
      : true;
    const seasonalValid = validateSeasonalVariations();
    if (
      (Object.keys(errors || {}).length !== 0 &&
        ["ALL"].includes(errors.BUTTON_TYPE)) ||
      !seqValid ||
      !seasonalValid
    ) {
      showSnackbar(
        <ul>
          {Object.keys(errors || {}).map((key) => {
            if (key === "ENROLLMENT_DURATION") return "";
            if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
            return "";
          })}
          {!seqValid && <li>Sequential cohorts setup is not valid</li>}
          {!seasonalValid && (
            <li>Country enrollment seasonal variations are not valid</li>
          )}
        </ul>,
        "error",
      );
      return;
    }
    const isNew = planId.includes("new-plan_") ? true : false;
    if (isNew) {
      savePlan({ isNew, redirect });
    } else {
      if (unsavedChange) savePlan({ isNew: false, undefined });
    }
  };

  const handleFileUploadClose = () => {
    setFileUploadOpen(false);
  };


  const handleFileUploadContinue = (newStudyPlanId) => {
    setFileUploadOpen(false);
    const currentUrl = `/ctpo/study-plans/${id}/${newStudyPlanId}`;
    
    if (history.location.pathname.includes('countries-and-site-groups')) {
      // If we're on the countries-and-site-groups page, just refresh
      window.location.reload();
    } else if (history.location.pathname === currentUrl) {
      // If we're already at the correct URL, force a page refresh
      window.location.reload();
    } else {
      // Otherwise, navigate to the new URL
      history.push(currentUrl);
    }
  };

  const handleExportPlan = () => {
    exportPlan();
  };

  const redirect = (lPlanId) => {
    history.push(`/ctpo/study-plans/${id}/${lPlanId}`);
  };

  const handleDisabled = () => {
    const mustValidateFields = ["studyPlanName", "studyPlanDescr"];
    for (let i = 0; i < mustValidateFields.length; i++) {
      if (errors[mustValidateFields[i]]) {
        return true;
      }
    }
    const hasErrors = Object.values(fieldErrors).some(
      (field) => field.errors && field.errors.length > 0,
    );
    return (
      hasErrors ||
      !studyPlanName ||
      !croProjectStartDateMillis ||
      !studyPlanDescr ||
      !finalProtocolStartDateMillis ||
      sites_to_activate.lowerBound === null ||
      !(sites_to_activate.lowerBound > 0) ||
      subjects_to_screen.lowerBound === null ||
      !(subjects_to_screen.lowerBound > 0) ||
      treatmentDuration === null ||
      !(treatmentDuration > 0) ||
      screeningPeriodDuration === null ||
      !(screeningPeriodDuration >= 0) ||
      (!hideEnrollmentDuration &&
        (overallEnrollmentDuration === null ||
          !(overallEnrollmentDuration >= 0)))
    );
  };

  return (
    <Container>
      <LeftButtonGroup>
        <TertiaryButton
          onClick={handleBackToList}
          disabled={saveLoading}
        >
          <ChevronLeftIcon />
          Back to Study Plan List
        </TertiaryButton>

      </LeftButtonGroup>

      <ButtonContainer>
        <TertiaryButton onClick={() => setFileUploadOpen(true)}>
          <DescriptionIcon classes={classes} /> UPLOAD PLAN
        </TertiaryButton>
        <TertiaryButton onClick={handleExportPlan}>
          <GetAppIcon classes={classes} /> EXPORT PLAN
        </TertiaryButton>
        <SecondaryButton
          onClick={handleCancel}
          disabled={saveLoading}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={
            handleDisabled() ||
            saveLoading ||
            isOverrideAndEmptyComment ||
            hasErrors.length > 0 ||
            !unsavedChange
          }
          onClick={handleSave}
        >
          {saveLoading ? <CircularProgress size="1em" /> : "Save Draft"}
        </PrimaryButton>
      </ButtonContainer>
      
      <UnsavedChangesModal
        open={open}
        onToggle={(prev) => setOpen(!prev)}
      />
      
      {fileUploadOpen && (
      <UploadPlanModal
        open={fileUploadOpen}
        onClose={handleFileUploadClose}
        onContinue={handleFileUploadContinue}
      />
    )}
    </Container>
  );
};

export default Controls;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-gap: 1em;
`;

const LeftButtonGroup = styled.div`
  display: flex;
  gap: 1em;
`;