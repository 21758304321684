import React, { createContext, useContext, useMemo } from 'react';
import { useUser } from "../../app/contexts/user.context";

const PermissionContext = createContext(undefined);

export const PermissionProvider = ({ children }) => {
  const { user } = useUser();
  
  const hasPermission = useMemo(() => {
    const readOnlyGroups = [
      "app_PremierPredictDev_ReadOnly",
      "app_PremierPredictLocalDev_ReadOnly",
      "app_PremierPredict_ReadOnly"
    ];
    return !readOnlyGroups.some(group => user?.groups?.includes(group) ?? false);
  }, [user?.groups]);

  const PermissionWrapper = ({
    children,
    fallback = null,
    disabledStyle = "opacity-50 cursor-not-allowed"
  }) => {
    // If the child is not a valid element, return null
    if (!React.isValidElement(children)) {
      return null;
    }
    
    // If user has permission, render the child as is
    if (hasPermission) {
      return children;
    }

    // Handle different types of form elements
    const child = React.Children.only(children);
    const childType = child.type;
    const childProps = child.props;

    // For Material-UI MenuItem, return null to hide it
    if (childType && childType.displayName === 'MenuItem') {
      return null;
    }

    // For button elements
    if (childType === 'button' || childProps.role === 'button') {
      return React.cloneElement(child, {
        disabled: true,
        className: `${childProps.className || ''} ${disabledStyle}`,
        onClick: (e) => e.preventDefault(),
      });
    }

    // For input elements
    if (childType === 'input' || childType === 'textarea' || childType === 'select') {
      return React.cloneElement(child, {
        disabled: true,
        readOnly: true,
        className: `${childProps.className || ''} ${disabledStyle}`,
      });
    }

    // For custom components that accept disabled prop
    if (childProps.hasOwnProperty('disabled')) {
      return React.cloneElement(child, {
        disabled: true,
        className: `${childProps.className || ''} ${disabledStyle}`,
      });
    }

    // If fallback is provided, render it
    if (fallback) {
      return fallback;
    }

    // Default: render read-only version
    return React.cloneElement(child, {
      className: `${childProps.className || ''} ${disabledStyle}`,
      onClick: (e) => e.preventDefault(),
    });
  };

  const usePermission = () => hasPermission;

  const value = {
    hasPermission,
    PermissionWrapper,
    usePermission,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (context === undefined) {
    throw new Error('usePermissionContext must be used within a PermissionProvider');
  }
  return context;
};

// Export individual hooks for convenience
export const usePermission = () => usePermissionContext().usePermission();
export const usePermissionWrapper = () => usePermissionContext().PermissionWrapper;