import React from "react";
import { TextField } from "@material-ui/core";
import { debounce } from "lodash";

export default function VisitInputField({ onVisitsChange, sv }) {
  // Initialize with empty string if sv.visitName is undefined/null
  const [localValue, setLocalValue] = React.useState(sv.visitName || "");

  // Update local state when prop changes
  React.useEffect(() => {
    if (sv.visitName !== undefined && sv.visitName !== null) {
      setLocalValue(sv.visitName);
    }
  }, [sv.visitName]);

  const debouncedUpdate = React.useCallback(
    debounce((name, value, key) => {
      onVisitsChange(name, value, key, "string"); // Explicitly pass "string" type
    }, 500),
    []
  );

  return (
    <TextField
      style={{ width: "100%" }}
      type="text"
      value={localValue}
      onChange={(e) => {
        const value = e.target.value;
        setLocalValue(value);
        debouncedUpdate(
          "visitName",
          value,
          sv.scheduleOfEventsVisitId || sv.key
        );
      }}
      variant="outlined"
      size="small"
      inputProps={{
        maxLength: 50,
      }}
    />
  );
}