export const HEADERS = (visit) => ({
  visitCategory: "Visit Category",
  visitName: "Visit Name",
  visitStudyDay: `Visit Study ${visit.visitIntervalType}`,
  visitVarianceType: "(+/-)",
  visitVariance: `Visit Window ${visit.visitWindowIntervalType}`,
  visitCost: "Visit Overhead",
  visitCostTotal: "Total Visit Cost",
  miEstSDRSDV: "100% SDR/SDV Time (mins)",
  miSampleVisit: "CRA Sampled Visit",
  miSuggestedOnSiteSDRSDV: "Onsite SDR/SDV (mins)",
  miSuggestedCDAReview: "CDA Review (mins)",
  dmUniqueCRFs: "Unique CRFs",
  dmNonUniqueCRFs: "Non-unique CRFs",
  // dmIntegratedEpro: "Integrated ePRO",
  // dmPaperEpro: "Paper PRO",
  // dmCentralLab: "Central Lab",
  // dmLocalLab: "Local Lab",
  commentsVisit: "Visit Comments",
  commentsCost: "Cost Comments",
  commentsClinicalStrategy: "Clinical Strategy Comments",
  commentsDataManagement: "Data Management Comments",
});

export const COUNTRY_HEADERS = (visit, SOECountries) => [
  "Visit Category",
  "Visit Name",
  `Visit Study ${visit.visitIntervalType}`,
  "(+/-)",
  `Visit Window ${visit.visitWindowIntervalType}`,
  ...SOECountries.filter((soc) => soc.siteGroupId).map(
    (soc) => `${soc.siteGroupName} - ${soc.dropDownListLabel} - Total Visit Cost`
  ),
];
