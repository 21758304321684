import { FormControl, MenuItem, TextField, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useStudyPlan } from "../../../../app/contexts/study.context";
import CustomDatePicker from "../../../../app/components/custom.date.picker";
import {
  VISIT_INTERVAL_TYPE_OPTIONS,
  CDR_REVIEW_OPTIONS,
  ADDITIONAL_TIME_ON_SITE_FACTOR,
  ESTIMATED_ACTIVATED_NON_ENROLLING_SITES,
  REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES,
  SUBJECT_REPORTING_AVERAGE_FREQUENCY,
  SUBJECT_REPORTING_HOURS_TO_GENERATE,
  STUDY_REPORTING_HOURS_TO_GENERATE,
} from "./form.constants";
import { formatDate } from "../../../../app/helpers/utils";

export const EditScheduleEventForm = ({
  errors,
  SOEEventParameters,
  handleChange,
  isProtocolVersionNA,
  setIsProtocolVersionNA,
  isProtocolDateNA,        // Add these props
  setIsProtocolDateNA  
}) => {
  const classes = useStyles();
  const { study } = useStudyPlan();

  const handleProtocolVersionNAToggle = () => {
    const newNAState = !isProtocolVersionNA;
    setIsProtocolVersionNA(newNAState);
    if (newNAState) {
      handleChange("protocolVersion", "");
    }
  };
  
  const handleProtocolDateNAToggle = () => {
    const newNAState = !isProtocolDateNA;
    setIsProtocolDateNA(newNAState);
    if (newNAState) {
      handleChange("protocolDate", null);
    }
  };


  return (
    <>
      <VContainer>
        <h5>General</h5>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsName}
            onChange={(e) =>
              handleChange("scheduleOfEventsName", e.target.value)
            }
            variant="outlined"
            label="SOE Name"
            InputLabelProps={{ classes }}
            required
            error={!!errors.scheduleOfEventsName}
            inputProps={{
              maxLength: 50,
            }}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsName}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsName || "").length
                } / ${50}`}</span>
              </AlignBetween>
            }
            size="small"
          />
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.scheduleOfEventsDescr}
            error={errors.scheduleOfEventsDescr}
            onChange={(e) =>
              handleChange("scheduleOfEventsDescr", e.target.value)
            }
            variant="outlined"
            label="SOE Description"
            InputLabelProps={{ classes }}
            required
            multiline
            inputProps={{
              maxLength: 2000,
            }}
            rows={4}
            helperText={
              <AlignBetween>
                <span>{errors.scheduleOfEventsDescr}</span>
                <span>{`${
                  (SOEEventParameters.scheduleOfEventsDescr || "").length
                } / ${2000}`}</span>
              </AlignBetween>
            }
            size="small"
          />
        </FormControl>
        <ProtocolVersionContainer>
          <ProtocolInputContainer>
            <FieldWrapper isDisabled={isProtocolVersionNA}>
              <TextField
                style={{ width: "100%" }}
                value={isProtocolVersionNA ? "" : SOEEventParameters.protocolVersion || ""}
                onChange={(e) => !isProtocolVersionNA && handleChange("protocolVersion", e.target.value)}
                variant="outlined"
                label="Protocol Version"
                InputLabelProps={{ classes }}
                required={!isProtocolVersionNA}
                disabled={isProtocolVersionNA}
                error={!isProtocolVersionNA && !!errors.protocolVersion}
                inputProps={{
                  maxLength: 25,
                }}
                helperText={
                  <AlignBetween>
                    <span>{!isProtocolVersionNA && errors.protocolVersion}</span>
                    <span>{`${
                      (SOEEventParameters.protocolVersion || "").length
                    } / ${25}`}</span>
                  </AlignBetween>
                }
              />
            </FieldWrapper>
            <NAToggle>
              <span>Not Available</span>
              <Switch
                checked={isProtocolVersionNA}
                onChange={handleProtocolVersionNAToggle}
                color="primary"
              />
            </NAToggle>
          </ProtocolInputContainer>
        </ProtocolVersionContainer>
        <ProtocolDateContainer>
          <DatePickerWrapper isDisabled={isProtocolDateNA}>
            <CustomDatePicker
              label="Protocol Date"
              onChange={(value) => !isProtocolDateNA && handleChange("protocolDate", formatDate(value))}
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              required={!isProtocolDateNA}
              disabled={isProtocolDateNA}
              style={{ zIndex: 100 }}
              error={!isProtocolDateNA && errors.protocolDate}
              dateFormat={"dd/MMM/yyyy"}
              selected={isProtocolDateNA ? null : SOEEventParameters.protocolDate}
              value={isProtocolDateNA ? null : SOEEventParameters.protocolDate}
            />
          </DatePickerWrapper>
          <NAToggle>
            <span>Not Available</span>
            <Switch
              checked={isProtocolDateNA}
              onChange={handleProtocolDateNAToggle}
              color="primary"
            />
          </NAToggle>
        </ProtocolDateContainer>
        <FormControl variant="outlined">
          <TextField
            label="SOE Status"
            id="soe-status"
            variant="outlined"
            fullWidth
            select
            value={SOEEventParameters.scheduleOfEventsStatus}
            onChange={(event) => {
              handleChange("scheduleOfEventsStatus", event.target.value);
            }}
            size="small"
          >
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="Final">Final</MenuItem>
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.visitIntervalType}
            required
            id="visit-interval-type-id"
            value={SOEEventParameters.visitIntervalType}
            onChange={(event) =>
              handleChange("visitIntervalType", event.target.value)
            }
            label="Visit Interval Type"
          >
            {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.visitWindowIntervalType}
            required
            id="visit-window-interval-type-id"
            value={SOEEventParameters.visitWindowIntervalType}
            onChange={(event) =>
              handleChange("visitWindowIntervalType", event.target.value)
            }
            label="Visit Window Interval Type"
          >
            {VISIT_INTERVAL_TYPE_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="study-plan"
            value={SOEEventParameters.studyPlanId}
            onChange={(event) =>
              handleChange("studyPlanId", event.target.value)
            }
            label="Study Plan"
          >
            {study?.studyPlans?.map((sp) => (
              <MenuItem value={sp.studyPlanId}>{sp.studyPlanName}</MenuItem>
            ))}
          </TextField>
        </FormControl>
      </VContainer>
      <VContainer>
        <h5>Clinical Strategy</h5>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            error={errors.CDAReviewPctTime}
            required
            id="cdareviewprctime"
            value={SOEEventParameters.CDAReviewPctTime}
            onChange={(event) =>
              handleChange("CDAReviewPctTime", event.target.value)
            }
            label="CDA Review % Time"
          >
            {CDR_REVIEW_OPTIONS?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.assessorName}
            onChange={(e) => handleChange("assessorName", e.target.value)}
            variant="outlined"
            label="Risk Strategy Assessor Name"
            InputLabelProps={{ classes }}
            multiline
            inputProps={{
              maxLength: 100,
            }}
            helperText={
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span>{`${
                  (SOEEventParameters.assessorName || "").length
                } / ${100}`}</span>
              </div>
            }
            size="small"
          />
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="additional-time-on-site-factor-id"
            value={SOEEventParameters.AddlTimeOnSitePct}
            onChange={(event) =>
              handleChange("AddlTimeOnSitePct", event.target.value)
            }
            label="Additional Time on Site factor"
          >
            {ADDITIONAL_TIME_ON_SITE_FACTOR?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="estimated-activated-non-enrolling-id"
            value={SOEEventParameters.EstActivatedNonEnrollingSitesPct}
            onChange={(event) =>
              handleChange(
                "EstActivatedNonEnrollingSitesPct",
                event.target.value,
              )
            }
            label="Estimated Activated Non-enrolling Sites"
          >
            {ESTIMATED_ACTIVATED_NON_ENROLLING_SITES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="required-administrative-imv-id"
            value={SOEEventParameters.ReqAdminIMVNonEnrollingSites}
            onChange={(event) =>
              handleChange("ReqAdminIMVNonEnrollingSites", event.target.value)
            }
            label='Required "Administrative" IMV for Non-Enrolling Sites '
          >
            {REQUIRED_ADMINISTRATIVE_IMV_NON_ENROLLING_SITES?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-average-frequency-id"
            value={SOEEventParameters.SubjectReportingAvgFrequency}
            onChange={(event) =>
              handleChange("SubjectReportingAvgFrequency", event.target.value)
            }
            label="Subject Reporting, Average Frequency "
          >
            {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
              <MenuItem value={type === "<blank value>" ? "" : type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-hours-to-generate-id"
            value={SOEEventParameters.SubjectReportingHoursToGenerate}
            onChange={(event) =>
              handleChange(
                "SubjectReportingHoursToGenerate",
                event.target.value,
              )
            }
            label="Subject Reporting, Hours to Generate "
          >
            {SUBJECT_REPORTING_HOURS_TO_GENERATE?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="subject-reporting-average-frequency-id"
            value={SOEEventParameters.StudyReportingAvgFrequency}
            onChange={(event) =>
              handleChange("StudyReportingAvgFrequency", event.target.value)
            }
            label="Study Reporting, Average Frequency "
          >
            {SUBJECT_REPORTING_AVERAGE_FREQUENCY?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl
          style={{ width: "100%" }}
          variant="outlined"
        >
          <TextField
            variant="outlined"
            fullWidth
            select
            size="small"
            id="study-reporting-hours-to-generate-id"
            value={SOEEventParameters.StudyReportingHoursToGenerate}
            onChange={(event) =>
              handleChange("StudyReportingHoursToGenerate", event.target.value)
            }
            label="Study Reporting, Hours to Generate "
          >
            {STUDY_REPORTING_HOURS_TO_GENERATE?.map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl variant="outlined">
          <TextField
            style={{ width: "100%" }}
            value={SOEEventParameters.StudyAdhocReview}
            onChange={(e) => {
              // only allow positive numeric values with two decimal places from 0.00 to 999.99
              if (
                e.target.value &&
                (!/^\d{1,3}(\.\d{1,2})?$/.test(e.target.value) ||
                  e.target.value >= 1000)
              ) {
                return;
              }

              handleChange("StudyAdhocReview", e.target.value);
            }}
            variant="outlined"
            label="Ad Hoc Review"
            InputLabelProps={{ classes }}
            type="number"
            size="small"
          />
        </FormControl>
      </VContainer>
    </>
  );
};

const useStyles = makeStyles({
  asterisk: {
    color: "red",
  },
});

const AlignBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const VContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2em;
  flex: 1;
`;

const ProtocolVersionContainer = styled.div`
  width: 100%;
`;

const ProtocolInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1em;
`;

const ProtocolDateContainer = styled.div`
  display: flex; 
  align-items: flex-start;
  gap: 1em;
`;

const FieldWrapper = styled.div`
  flex: 1;
  opacity: ${props => props.isDisabled ? 0.38 : 1};
`;

const DatePickerWrapper = styled.div`
  flex: 1;
  opacity: ${props => props.isDisabled ? 0.38 : 1};
  pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
`;

const NAToggle = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;

  span {
    order: -1;
  }
`;